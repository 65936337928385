import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
// import moment from 'moment';

// import { sched } from './data';

export default () => {
  /*
  const renderContests = num => {
    return sched[num - 1].map(c => (
      <ul>
        Contest #{c.num} will be {moment(c.date).format('MMM D, YYYY')} at {c.loc}
      </ul>
    ));
  };
  */
 
  const renderGroup = num => (
    <Card key={num}>
      <Accordion.Toggle as={Card.Header} eventKey={num}>
        Group {num}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={num}>
        <Card.Body>
          {/*
          <ul className="list-unstyled">
            {renderContests(num)}
          </ul>
          */}
          Contests will be held by each school in accordance with their respective health and safety guidelines.
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );

  return (
    <Accordion defaultActiveKey="1" >
      {[1, 2, 3, 4, 5, 6].map(n => renderGroup(n))}
    </Accordion>
  );
};