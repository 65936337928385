import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import { schools } from './data';

export default () => {
  const renderGroupSchools = schools => schools.map(school => <li key={school}>{ school }</li>);

  const renderGroups = () => {
    return schools.map(group => (
      <li key={group.num}>
        <h6>Group {group.num}</h6>
        <ul className="list-unstyled ml-3 mb-3">{renderGroupSchools(group.schools)}</ul>
      </li>
    ));
  }

  return (
    <Accordion defaultActiveKey="schools">
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="schools">
          Participating Schools
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="schools">
          <Card.Body>
            <ul className="list-unstyled">
              {renderGroups()}
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>        
      {/* <Card>
        <Accordion.Toggle as={Card.Header} eventKey="board">
          Board of Directors
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="board">
          <Card.Body>Here it is</Card.Body>
        </Accordion.Collapse>
      </Card>   */}
    </Accordion>
  );
}