import React from 'react';

import Card from 'react-bootstrap/Card';

export default () => {
  return (
    <div>
      <Card className="mb-3">
        <Card.Body>
          <Card.Title className="text-info">2023-2024 Contests</Card.Title>
          <Card.Text>The contest archives have been updated to include all constests through the 2023-2024 school year.</Card.Text>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Body>
          <Card.Title className="text-info">2024-2025 Advisor's Meeting</Card.Title>
          <Card.Text>The 2024-2025 BCML Advisor's Meeting will be held in September 2024. It will be held virtually on Zoom. Details and link will arrive via email to all registered advisors.</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};
