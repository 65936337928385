import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

export default () => {
  const renderContest = (year, n) => (year === 2020 && n > 4) || (year === 2021 && n > 5) ? '' : (
    <li key={n}>
      <a href={`${process.env.PUBLIC_URL}/files/BCML${("0" + (year % 100)).slice(-2)}${("0" + ((year + 1) % 100)).slice(-2)}c${n}.pdf`}>Contest {n}</a>
      &nbsp;
      <a href={`${process.env.PUBLIC_URL}/files/BCML${("0" + (year % 100)).slice(-2)}${("0" + ((year + 1) % 100)).slice(-2)}s${n}.pdf`}>(solutions)</a>
    </li>
  );

  const renderYear = year => <ul className="list-unstyled">{[1, 2, 3, 4, 5, 6].map(n => renderContest(year, n))}</ul>;

  const renderArchives = () => {
    return [2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006].map(year => (
      <Card key={year}>
        <Accordion.Toggle as={Card.Header} eventKey={year}>
          {year} - {year + 1}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={year}>
          <Card.Body>{renderYear(year)}</Card.Body>
        </Accordion.Collapse>
      </Card>
    ));
  };

  return (
    <Accordion defaultActiveKey={2023}>
      {renderArchives()}
    </Accordion>
  );
}