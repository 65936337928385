export const schools = [
  {
    num: 1,
    schools: [
      'Indian Hills High School',
      'Mahwah High School',
      'Midland Park High School',
      'Park Ridge High School',
      'Ramapo High School',
      'Ramsey High School'
    ]
  },
  {
    num: 2,
    schools: [
      'Fair Lawn High School',
      'Glen Rock High School',
      'Paramus High School',
      'Ridgewood High School'
    ]
  },
  {
    num: 3,
    schools: [
      'Bergenfield High School',
      'Dumont High School',
      'New Milford High School',
      'River Dell High School',
      'Tenafly High School',
    ]
  },
  {
    num: 4,
    schools: [
      'Dwight Englewood High School',
      'Fort Lee High School',
      'Ridgefield Memorial High School',
      'Ridgefield Park High School'
    ]
  },
  {
    num: 5,
    schools: [
      'Becton Regional High School',
      'Garfield High School',
      'Lodi High School',
      'Rutherford High School'
    ]
  },
  {
    num: 6,
    schools: [
      'Cresskill High School',
      'Northern Valley Regional High School (Demarest)',
      'Northern Valley Regional High School (Old Tappan)',
      'Pascack Hills High School',
      'Westwood High School'
    ]
  }
];

export const sched = [
  [
    {
      num: 1,
      date: new Date(2019, 9, 17),
      loc: 'Ramapo'
    },
    {
      num: 2,
      date: new Date(2019, 10, 21),
      loc: 'Ramsey'
    },
    {
      num: 3,
      date: new Date(2019, 11, 19),
      loc: 'Park Ridge'
    },
    {
      num: 4,
      date: new Date(2020, 0, 16),
      loc: 'Midland Park'
    },
    {
      num: 5,
      date: new Date(2020, 1, 6),
      loc: 'Mahwah'
    },
    {
      num: 6,
      date: new Date(2020, 2, 19),
      loc: 'Tenafly'
    }
  ], [
    {
      num: 1,
      date: new Date(2019, 9, 17),
      loc: 'Ridgewood'
    },
    {
      num: 2,
      date: new Date(2019, 10, 21),
      loc: 'Fair Lawn'
    },
    {
      num: 3,
      date: new Date(2019, 11, 19),
      loc: 'Glen Rock'
    },
    {
      num: 4,
      date: new Date(2020, 0, 16),
      loc: 'Fair Lawn'
    },
    {
      num: 5,
      date: new Date(2020, 1, 6),
      loc: 'Ridgewood'
    },
    {
      num: 6,
      date: new Date(2020, 2, 19),
      loc: 'Tenafly'
    }
  ], [
    {
      num: 1,
      date: new Date(2019, 9, 17),
      loc: 'Tenafly'
    },
    {
      num: 2,
      date: new Date(2019, 10, 21),
      loc: 'Bergenfield'
    },
    {
      num: 3,
      date: new Date(2019, 11, 19),
      loc: 'River Dell'
    },
    {
      num: 4,
      date: new Date(2020, 0, 16),
      loc: 'New Milford'
    },
    {
      num: 5,
      date: new Date(2020, 1, 6),
      loc: 'Dumont'
    },
    {
      num: 6,
      date: new Date(2020, 2, 19),
      loc: 'Tenafly'
    }
  ], [
    {
      num: 1,
      date: new Date(2019, 9, 17),
      loc: 'TBD'
    },
    {
      num: 2,
      date: new Date(2019, 10, 21),
      loc: 'TBD'
    },
    {
      num: 3,
      date: new Date(2019, 11, 19),
      loc: 'TBD'
    },
    {
      num: 4,
      date: new Date(2020, 0, 16),
      loc: 'TBD'
    },
    {
      num: 5,
      date: new Date(2020, 1, 6),
      loc: 'TBD'
    },
    {
      num: 6,
      date: new Date(2020, 2, 19),
      loc: 'Tenafly'
    }
  ], [
    {
      num: 1,
      date: new Date(2019, 9, 17),
      loc: 'Rutherford'
    },
    {
      num: 2,
      date: new Date(2019, 10, 21),
      loc: 'Becton'
    },
    {
      num: 3,
      date: new Date(2019, 11, 19),
      loc: 'Lodi'
    },
    {
      num: 4,
      date: new Date(2020, 0, 16),
      loc: 'Garfield'
    },
    {
      num: 5,
      date: new Date(2020, 1, 6),
      loc: 'Rutherford'
    },
    {
      num: 6,
      date: new Date(2020, 2, 19),
      loc: 'Tenafly'
    }
  ], [
    {
      num: 1,
      date: new Date(2019, 9, 17),
      loc: 'TBD'
    },
    {
      num: 2,
      date: new Date(2019, 10, 21),
      loc: 'TBD'
    },
    {
      num: 3,
      date: new Date(2019, 11, 19),
      loc: 'TBD'
    },
    {
      num: 4,
      date: new Date(2020, 0, 16),
      loc: 'TBD'
    },
    {
      num: 5,
      date: new Date(2020, 1, 6),
      loc: 'TBD'
    },
    {
      num: 6,
      date: new Date(2020, 2, 19),
      loc: 'Tenafly'
    }
  ]
];
