import React from 'react';
import { withRouter } from 'react-router-dom';

import { LinkContainer } from 'react-router-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const Header = ({ location }) => {
  return (
    <Navbar className="mb-3" bg="info" variant="dark" expand="md" collapseOnSelect>
      <LinkContainer to="/">
        <Navbar.Brand>Bergen County Math League</Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Nav>
          <LinkContainer to="/schedule">
            <Nav.Link active={location.pathname === '/schedule'}>Schedule</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/archive">
            <Nav.Link active={location.pathname === '/archive'}>Archive</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/about">
            <Nav.Link active={location.pathname === '/about'}>About</Nav.Link>
          </LinkContainer>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default withRouter(Header);