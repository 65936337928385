import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Header from './Header';
import Home from './Home';
import Schedule from './Schedule';
import Archive from './Archive';
import About from './About';

function App() {
  return (
    <Router>
      <Header />
      <Container>
        <Row>
          <Col>
            <Route path="/" exact component={Home} />
            <Route path="/schedule" component={Schedule} />
            <Route path="/archive" component={Archive} />
            <Route path="/about" component={About} />
          </Col>
        </Row>
      </Container>
    </Router>
  );
}

export default App;
